import React from "react"
// @ts-ignore
import { Prismic_PostBodyEmbed } from "../../../graphql-types"

interface Props {
  slice: Prismic_PostBodyEmbed
}

const Embed: React.FC<Props> = ({ slice }) => {
  if (!slice.primary) return null

  const extendedHtml = slice.primary.embed_link.html.replace(
    "<iframe",
    `<iframe title="${slice.primary.embed_link.title}"`
  )

  return (
    <div
      className="embed-responsive aspect-ratio-16/9 mb-8"
      dangerouslySetInnerHTML={{ __html: extendedHtml }}
    />
  )
}

export default Embed
