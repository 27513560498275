import React from "react"
import { graphql } from "gatsby"

// @ts-ignore
import { RichText } from "prismic-reactjs"
import { BlogPostQuery } from "../../graphql-types"
import SliceZone from "../components/slice/SliceZoneComponent"
import LayoutComponent from "../components/layout/LayoutComponent"
import SEO from "../components/seo/SeoComponent"
import Comments from "../components/CommentsComponen"

interface Props {
  data: BlogPostQuery
}

const PostTemplate: React.FC<Props> = ({ data }: Props) => {
  let doc
  if (data.prismic.allPosts.edges) {
    doc = data.prismic.allPosts.edges.slice(0, 1).pop()
  }

  if (!doc || !doc.node._meta.uid) {
    return null
  }

  return (
    <LayoutComponent>
      {doc.node.title && doc.node.excerpt && (
        <SEO
          title={RichText.asText(doc.node.title)}
          description={RichText.asText(doc.node.excerpt)}
          image={doc.node.image}
          path={doc.node._meta.uid}
          article={{
            datePublished: doc.node._meta.firstPublicationDate,
            dateModified: doc.node._meta.lastPublicationDate,
          }}
        />
      )}
      <div>
        <h1 className="text-3xl mb-2">{RichText.asText(doc.node.title)}</h1>
        <p className="mb-5 text-gray-700 text-sm">{doc.node.date}</p>
        <SliceZone slices={doc.node.body} />
        <Comments
          id={doc.node._meta.id}
          uid={doc.node._meta.uid}
          title={doc.node.title.text}
        />
      </div>
    </LayoutComponent>
  )
}

export default PostTemplate

export const query = graphql`
  query BlogPost($uid: String) {
    prismic {
      allPosts(uid: $uid) {
        edges {
          node {
            _meta {
              id
              uid
              firstPublicationDate
              lastPublicationDate
            }
            title
            excerpt
            image
            date
            body {
              __typename
              ... on PRISMIC_PostBodyText {
                type
                label
                primary {
                  text
                }
              }
              ... on PRISMIC_PostBodyCode {
                type
                label
                primary {
                  code
                }
              }
              ... on PRISMIC_PostBodyQuote {
                type
                label
                primary {
                  quote
                  author
                }
              }
              ... on PRISMIC_PostBodyEmbed {
                type
                label
                primary {
                  embed_link
                }
              }
              ... on PRISMIC_PostBodyImage {
                type
                label
                primary {
                  horizontal_padding
                  content_image
                  content_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 70) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
