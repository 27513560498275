import React from "react"
// @ts-ignore
import { RichText } from "prismic-reactjs"
import { Prismic_PostBodyText } from "../../../graphql-types"

interface Props {
  slice: Prismic_PostBodyText
}

const Text: React.FC<Props> = ({ slice }) => {
  if (!slice.primary) return null

  return (
    <div className="mb-5 text">
      <RichText render={slice.primary.text} />
    </div>
  )
}

export default Text
