import React from "react"
import Text from "./TextComponent"
import Code from "./CodeComponent"
import {
  Prismic_Post,
  Prismic_PostBodyCode,
  Prismic_PostBodyEmbed,
  Prismic_PostBodyImage,
  Prismic_PostBodyQuote,
  Prismic_PostBodyText,
} from "../../../graphql-types"
import Quote from "./QuoteComponent"
import Embed from "./EmbedComponent"
import Image from "./ImageComponent"

interface Props {
  slices: Prismic_Post["body"]
}

const SliceZone: React.FC<Props> = ({ slices }) => {
  if (!slices) return null

  return (
    <>
      {slices.map((slice, index) => {
        switch (slice.type) {
          case "text":
            return (
              <Text
                slice={slice as Prismic_PostBodyText}
                key={`slice-${index}`}
              />
            )
          case "quote":
            return (
              <Quote
                slice={slice as Prismic_PostBodyQuote}
                key={`slice-${index}`}
              />
            )
          case "embed":
            return (
              <Embed
                slice={slice as Prismic_PostBodyEmbed}
                key={`slice-${index}`}
              />
            )
          case "code":
            return (
              <Code
                slice={slice as Prismic_PostBodyCode}
                key={`slice-${index}`}
              />
            )
          case "image":
            return (
              <Image
                slice={slice as Prismic_PostBodyImage}
                key={`slice-${index}`}
              />
            )
          default:
            return null
        }
      })}
    </>
  )
}

export default SliceZone
