import React from "react"
import { DiscussionEmbed } from "disqus-react"

interface Props {
  id: string
  uid: string
  title: string
}

const Comments: React.FC<Props> = ({ id, uid, title }) => {
  if (!process.env.GATSBY_DISQUS_NAME) {
    return null
  }

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: {
      identifier: id,
      title: title,
      url: `https://jeroendruwe.be/${uid}/`,
    },
  }

  return <DiscussionEmbed {...disqusConfig} />
}

export default Comments
