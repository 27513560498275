import React from "react"
// @ts-ignore
import { Prismic_PostBodyImage } from "../../../graphql-types"
import FluidImage from "../FluidImageComponent"

interface Props {
  slice: Prismic_PostBodyImage
}

const Image: React.FC<Props> = ({ slice }) => {
  if (!slice.primary) return null
  return (
    <div
      className={
        "mb-8 " + (slice.primary.horizontal_padding ? "sm:px-20 md:px-40" : "")
      }
    >
      <FluidImage
        image={slice.primary.content_imageSharp}
        alt={slice.primary.content_image.alt}
        fallback={slice.primary.content_image.url}
      />
    </div>
  )
}

export default Image
