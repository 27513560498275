import React from "react"
import { Prismic_PostBodyCode } from "../../../graphql-types"
// @ts-ignore
import { RichText } from "prismic-reactjs"
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter"
import jsx from "react-syntax-highlighter/dist/cjs/languages/prism/jsx"
import tsx from "react-syntax-highlighter/dist/cjs/languages/prism/tsx"
import java from "react-syntax-highlighter/dist/cjs/languages/prism/java"
import javascript from "react-syntax-highlighter/dist/cjs/languages/prism/javascript"
import powershell from "react-syntax-highlighter/dist/cjs/languages/prism/powershell"
import yaml from "react-syntax-highlighter/dist/cjs/languages/prism/yaml"
import json from "react-syntax-highlighter/dist/cjs/languages/prism/json"
import sql from "react-syntax-highlighter/dist/cjs/languages/prism/sql"
import typescript from "react-syntax-highlighter/dist/cjs/languages/prism/typescript"
import atomDark from "react-syntax-highlighter/dist/cjs/styles/prism/atom-dark"

SyntaxHighlighter.registerLanguage("jsx", jsx)
SyntaxHighlighter.registerLanguage("tsx", tsx)
SyntaxHighlighter.registerLanguage("java", java)
SyntaxHighlighter.registerLanguage("javascript", javascript)
SyntaxHighlighter.registerLanguage("powershell", powershell)
SyntaxHighlighter.registerLanguage("yaml", yaml)
SyntaxHighlighter.registerLanguage("json", json)
SyntaxHighlighter.registerLanguage("sql", sql)
SyntaxHighlighter.registerLanguage("typescript", typescript)

interface Props {
  slice: Prismic_PostBodyCode
}

const Code: React.FC<Props> = ({ slice }) => {
  if (!slice.primary) return null

  return (
    <div className="mb-8">
      <SyntaxHighlighter
        showLineNumbers={true}
        style={atomDark}
        language={slice.label ? slice.label : "javascript"}
        customStyle={{ fontFamily: "inherit", fontSize: 15 }}
        codeTagProps={{ style: { fontFamily: "inherit", fontSize: 15 } }}
      >
        {RichText.asText(slice.primary.code)}
      </SyntaxHighlighter>
    </div>
  )
}

export default Code
