import React from "react"
// @ts-ignore
import { RichText } from "prismic-reactjs"
import { Prismic_PostBodyQuote } from "../../../graphql-types"

interface Props {
  slice: Prismic_PostBodyQuote
}

const Quote: React.FC<Props> = ({ slice }) => {
  if (!slice.primary) return null

  return (
    <blockquote className="mb-8 italic text-gray-700 border-l-4 border-neutral-500 p-4">
      <RichText render={slice.primary.quote} />
      {slice.primary.author && (
        <p className="mt-3 text-sm">
          - {RichText.asText(slice.primary.author)}
        </p>
      )}
    </blockquote>
  )
}

export default Quote
